/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'app/variables.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-progress-bar .mat-progress-bar-buffer {
  background: #E4E8EB;
}

mat-progress-bar .mat-progress-bar-fill::after {
  background-color: $button-background-color;
}

a:hover {
  cursor: pointer;
}

.bg-altec {
  background-color: $button-background-color;
}

.bg-altec-warn {
  background-color: $warn-color;
}

.btn-light {
  color: #333;
  background-color: #FFF;
  border: 1px solid #aaa;

  &:hover {
    background-color: #F3F3F3;
    border: 1px solid #aaa;
  }
}

.button-success {
  background-color: $button-success-color;
}

.text-altec-warn {
  color: $warn-color;
}

.error-text {
  color: $error-color;
}

.error-field {
  border-color: $error-color;
}

.error-field::placeholder {
  color: $error-color;
}

.error-select {
  color: $error-color;
  border-color: $error-color;
}

.success-text {
  color: $success-color;
}

.alt-link {
  color: $link-blue;
  cursor: pointer;
}

.loading {
  animation: color-shift-skeleton-loading 2.5s infinite ease-in-out;
  background: rgb(205, 205, 205);
}

.cursor-pointer {
  cursor: pointer;
}

.loading-image {
  height: 200px;
}

.loading-text-field {
  height: 20px;
}

.loading-header {
  height: 30px;
}

.loading-image-fluid::before {
    display: block;
    content: '';
    padding-top: calc(100% * 2 / 3);
}

.table-load-odd {
  animation: color-shift-skeleton-loading 2.5s infinite ease-in-out;
  background: rgb(205, 205, 205);
}

.table-load-even {
  animation: color-shift-skeleton-loading2 2.5s infinite ease-in-out;
  background: rgb(255, 255, 255);
}

.inline-block {
  display: inline-block;
}

// Background method for skeleton loading in case we decide to go back to it
@keyframes background-shift-skeleton-loading {
  0% {
    background-position: -200px;
  }
  100% {
    background-position: 600px;
  }
}

@keyframes color-shift-skeleton-loading {
    0% {
        background-color: rgb(210, 210, 210);
    }
    50% {
        background-color: rgb(175, 175, 175)
    }
    100% {
        background-color: rgb(210, 210, 210)
    }
}

@keyframes color-shift-skeleton-loading2 {
  0% {
      background-color: rgb(255, 255, 255);
  }
  50% {
      background-color: rgb(220, 220, 220)
  }
  100% {
      background-color: rgb(255, 255, 255)
  }
}

// Helpers

.w-5 {
  width: 5%
}
.w-10 {
  width: 10%
}
.w-20 {
  width: 20%
}
.w-30 {
  width: 30%
}
.w-40 {
  width: 40%
}
.w-60 {
  width: 60%
}
.w-70 {
  width: 70%
}
.w-80 {
  width: 80%
}
.w-90 {
  width: 90%
}


