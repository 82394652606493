// APP COLOR VARIABLES

$link-color: #EFB310;
$link-blue: #1D81B0;
$link-background-color: #EFB310;
$button-background-color: #EFB310;
$button-success-color: #24804E;
$brand-primary: #EFB310;
$brand-primary-dark: #D7A10F;

$error-color: #dc3545;
$warn-color: #b96353;
$success-color: #008000;

$text-light: white;
$text-dark: black;

$border-width: 2px;
$border-color: #AAAAAA;
$border-background: #F3F3F3;

$top-nav-background-color: #000000;
$side-nav-background-color: #2e2e2e;
$footer-background-color: #000000;
$off-white: #F2F2F2;
